import RightArrow from '@components/svgs/svgRightArrow';
import styles from '@styles/common-scss/card/_featuredCard.module.scss';
import Image from 'next/image';
import React, { useState } from 'react';

export default function FeaturedCard({ site, index }: any): JSX.Element {
    const [imgSrc, setImgSrc] = useState(site.thumbnail);
    return (
        <>
            <a
                href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/OpenSite.aspx?SiteID=${site.links}${site.linkType && site.linkType !== 'default' ? `&type=${site.linkType}` : ''}`}
                rel="nofollow no-referrer"
                target="_blank"
                title={'Visit ' + site.title}
                data-event-category="Opensite"
                data-opensite-path="outgoing,default,featured-review-thumbs"
                data-event-label={`${site.title} (${site.links})`}
                data-event-action="featured-review-thumb"
                data-opensite-site={`${site.title} (${site.links})`}>
                <div className={styles.container}>
                    {/* Thumbnail */}
                    <div className={`${styles.thumbnail}`}>
                        <div className={styles.image}>
                            <Image
                                unoptimized
                                alt={`Site thumbnail`}
                                className={styles.image}
                                src={imgSrc}
                                onError={() => {
                                    setImgSrc('https://assets.rabbitsreviews.com/images/rabbitslogo.png');
                                }}
                                layout="fill"
                                objectFit="cover"
                                priority={index === 0}
                            />
                        </div>
                    </div>
                    {/* Thumbnail End */}
                    <div className={`${styles.content}`}>
                        <h3>
                            <div style={{ color: site.color.toUpperCase() !== 'N/A' ? site.color : '' }} className={styles.title}>
                                {site.title}
                            </div>
                        </h3>
                        <RightArrow />
                    </div>
                </div>
            </a>
        </>
    );
}
