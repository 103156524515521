import styles from '@styles/list-page-scss/_subTabs.module.scss';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

function BubbleTag({ title, isActive, handleClick }) {
    return (
        <div
            className={`${styles.bubbleTag} ${isActive ? styles.active : ''}`}
            onClick={handleClick}
            onKeyDown={handleClick}
            role="button"
            tabIndex={0}>
            {title}
        </div>
    );
}

function SortSubTabs({ categoryTitleSlugData }: any) {
    const router = useRouter();
    const { category } = router.query;

    // Initialize active filters from the URL query parameter
    const [activeFilters, setActiveFilters] = useState([]);
    const [bubbleTags, setBubbleTags] = useState(categoryTitleSlugData || []);

    useEffect(() => {
        if (category) {
            // Map slugs from the URL and find their corresponding titles
            const filtersFromUrl = Array.isArray(category) ? category.map(decodeURI) : category.split(',').map(decodeURI);

            const titlesFromSlugs = filtersFromUrl
                .map((slug) => {
                    const match = categoryTitleSlugData.find((item) => item.slug === slug);
                    return match ? match.title : null;
                })
                .filter(Boolean); // Remove null values (non-matching slugs)

            // Update state with the titles
            setActiveFilters(titlesFromSlugs);
        }
    }, [category, categoryTitleSlugData]);

    const updateQueryParams = (filters) => {
        const query = { ...router.query };
        delete query.page;
        if (filters.length > 0) {
            // Join filters with commas and encode using encodeURI
            query.category = filters.map(encodeURI).join(',');
        } else {
            // Remove 'category' from the query if no filters
            delete query.category;
        }

        // Build query string manually
        const queryString = Object.entries(query)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')
            .toLowerCase();

        router.push({ pathname: router.pathname, query: queryString }, undefined, { shallow: true });
    };

    const handleFilterClick = (title) => {
        let updatedFilters = [];

        if (activeFilters.includes(title)) {
            updatedFilters = activeFilters.filter((filter) => filter !== title);
        } else {
            updatedFilters = [...activeFilters, title];
        }

        // Map titles to slugs
        const slugs = updatedFilters
            .map((filter) => {
                const category = categoryTitleSlugData.find((item) => item.title === filter);
                return category ? category.slug : null;
            })
            .filter(Boolean); // Remove any null values

        setActiveFilters(updatedFilters);
        updateQueryParams(slugs); // Pass slugs to query params
    };
    return (
        <div className={styles.filterList}>
            <div className={styles.filteredList}>
                {bubbleTags
                    .filter((tag) => activeFilters.includes(tag.title))
                    .map((tag) => (
                        <BubbleTag key={tag.title} title={tag.title} isActive handleClick={() => handleFilterClick(tag.title)} />
                    ))}
                {activeFilters.length > 0 && (
                    <div className={styles.clearFilters}>
                        <button
                            onClick={() => {
                                setActiveFilters([]);
                                updateQueryParams([]);
                            }}>
                            X
                        </button>
                    </div>
                )}
            </div>
            {bubbleTags
                .filter((tag) => !activeFilters.includes(tag.title))
                .map((tag) => (
                    <BubbleTag
                        key={tag.title}
                        title={tag.title}
                        isActive={false}
                        handleClick={() => handleFilterClick(tag.title)}
                    />
                ))}
        </div>
    );
}

export default SortSubTabs;
