/* eslint-disable jsx-a11y/anchor-is-valid */
import TextToSVG from '@components/svgs/letters';
import SVGOF from '@components/svgs/svgOnlyfans';
import styles from '@styles/common-scss/card/_onlyfansCard.module.scss';
import Image from 'next/image';
import React, { useState } from 'react';

export default function FeaturedCard({ site }: any): JSX.Element {
    const [imgSrc, setImgSrc] = useState(`https://assets.rabbitsreviews.com/images/of/${site.thumb}`);

    const sendToOnlyfans = () => {
        const url = site.tourlink; // replace with your actual URL
        window.open(url, '_blank'); // Opens the link in a new tab
    };

    return (
        <>
            <button
                style={{ height: '100%', width: '100%' }}
                onClick={() => sendToOnlyfans()}
                title={'Visit ' + site.displayText ? site.displayText : site.name}
                className={styles.onlyfansCard}>
                <a
                    data-event-category="Onlyfans"
                    data-opensite-path="onlyfans pages"
                    data-event-label={site.displayText ? site.displayText : site.name}
                    data-event-action="onlyfans-account"
                    data-opensite-site={site.displayText ? site.displayText : site.name}>
                    <div className={styles.container}>
                        {/* Thumbnail */}
                        <div className={`${styles.thumbnail}`}>
                            <div className={styles.ofLogo}>
                                <SVGOF />
                            </div>
                            <div className={styles.image}>
                                <Image
                                    unoptimized
                                    alt={`OF Account thumbnail`}
                                    className={styles.image}
                                    src={imgSrc}
                                    onError={() => {
                                        setImgSrc('https://assets.rabbitsreviews.com/images/rabbitslogo.png');
                                    }}
                                    layout="fill"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                        {/* Thumbnail End */}
                        <div className={`${styles.content}`}>
                            <TextToSVG text={site.displayText ? site.displayText : site.name} />
                        </div>
                    </div>
                </a>
            </button>
        </>
    );
}
